import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	touch: {
		background: theme.palette.background.light,
		display: "flex",
		flexDirection: "column",
		minHeight: "100vh",
		padding: "136px 0 0"
	},
	menu: {
		display: "flex",
		flex: 1,
		padding: theme.spacing(3)
	}
}));

export default styles;
