import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	menu: {
		display: "grid",
		flex: 1,
		gap: theme.spacing(2),
		gridAutoRows: "minmax(100px, 1fr)",
		gridTemplateColumns: "repeat(2, 1fr)",
		width: "100%",
		[theme.breakpoints.up("sm")]: {
			gap: theme.spacing(3)
		},
		[theme.breakpoints.up("md")]: {
			gridTemplateColumns: "repeat(3, 1fr)"
		},
		[theme.breakpoints.up("lg")]: {
			gap: theme.spacing(4),
			gridTemplateColumns: "repeat(4, 1fr)"
		}
	}
}));

export default styles;
