import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Typography
} from "@material-ui/core";
import { AppContext, PageContext, changeLanguage } from "@Core";

import styles from "./Language.styles";

const Language = () => {
	const [appData] = useContext(AppContext);
	const [pageData] = useContext(PageContext);
	const { languages = [] } = appData || {};
	const { meta: { links = [] } = {} } = pageData?.data || {};
	const [open, setOpen] = useState(false);
	const { t, i18n } = useTranslation();
	const css = styles();

	const handleClick = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleChangeLanguage = isoCode => {
		handleClose();

		changeLanguage(i18n, isoCode);

		window.location.href = links?.[isoCode] ?? `/`;
	};

	if (languages?.length < 2) {
		return <></>;
	}

	return (
		<>
			<div className={css.language}>
				<button className={css.button} onClick={handleClick}>
					<Typography className={css.label} component="span">
						{i18n.language}
					</Typography>
				</button>
			</div>
			{languages && (
				<Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
					<DialogTitle className={css.dialogTitle}>
						<Typography className={css.headline} component="p">
							{t("site.ui.headlines.language")}
						</Typography>
					</DialogTitle>
					<DialogContent className={css.dialogContent}>
						{languages.map(({ isoCode, title }, index) => {
							return (
								<React.Fragment key={index}>
									{isoCode !== i18n.language && (
										<Box>
											<Button
												className={css.link}
												size="small"
												onClick={() => handleChangeLanguage(isoCode)}
											>
												{title}
											</Button>
										</Box>
									)}
								</React.Fragment>
							);
						})}
					</DialogContent>
				</Dialog>
			)}
		</>
	);
};

export default Language;
