import React, { Suspense, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import CssBaseline from "@material-ui/core/CssBaseline";
import createTheme from "@material-ui/core/styles/createTheme";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import ReactGA from "react-ga4";
import customTheme from "./themes/kaiserlodge";
import AppContext from "@Core/context/appContext";
import BookmarkContext from "@Core/context/bookmarkContext";
import MenuContext from "@Core/context/menuContext";
import PageContext from "@Core/context/pageContext";
import UserContext from "@Core/context/userContext";
import UserMenuContext from "@Core/context/userMenuContext";
import ChangePage from "@Core/helper/changePage";
import PushNotificationsBanner from "@Core/components/push-notifications-banner";
import useLinkEventListener from "@Core/hooks/useLinkEventListener";
import DetectPage from "./helper/detectPage";
import Ui from "@micado-digital/react-ui/components/Ui";
import {
	applyPolyfills as ialApplyPolyfills,
	defineCustomElements as ialDefineCustomElements
} from "@micado-digital/micado-ial/loader";

import "./css/index.css";

const themeDefault = createTheme(customTheme);
const themeGrey = createTheme(customTheme, {
	palette: { background: { default: "#F5F1EB" } }
});

const App = () => {
	const { REACT_APP_GOOGLE_ANALYTICS_ID } = process.env;
	const bookmarkLocalStorage = localStorage.getItem("bookmark");

	const [appData, setAppData] = useState();
	const [pageData, setPageData] = useState();
	const [userData, setUserData] = useState();
	const [menuOpen, setMenuOpen] = useState(false);
	const [userMenuOpen, setUserMenuOpen] = useState(false);
	const [bookmarkContext, setBookmarkContext] = useState(
		JSON.parse(bookmarkLocalStorage) || []
	);

	useLinkEventListener();

	const queryClient = new QueryClient();

	if (REACT_APP_GOOGLE_ANALYTICS_ID) ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS_ID);

	return (
		<QueryClientProvider client={queryClient}>
			<Suspense fallback={<></>}>
				<ThemeProvider theme={pageData?.theme === "grey" ? themeGrey : themeDefault}>
					<AppContext.Provider value={[appData, setAppData]}>
						<PageContext.Provider value={[pageData, setPageData]}>
							<UserContext.Provider value={[userData, setUserData]}>
								<MenuContext.Provider value={[menuOpen, setMenuOpen]}>
									<UserMenuContext.Provider value={[userMenuOpen, setUserMenuOpen]}>
										<BookmarkContext.Provider value={[bookmarkContext, setBookmarkContext]}>
											<MuiPickersUtilsProvider utils={MomentUtils}>
												<CssBaseline />
												<Router>
													<Ui>
														<ChangePage />
														<Switch>
															<Route path="/*" component={DetectPage} />
														</Switch>
														<PushNotificationsBanner />
													</Ui>
												</Router>
											</MuiPickersUtilsProvider>
										</BookmarkContext.Provider>
									</UserMenuContext.Provider>
								</MenuContext.Provider>
							</UserContext.Provider>
						</PageContext.Provider>
					</AppContext.Provider>
				</ThemeProvider>
			</Suspense>
		</QueryClientProvider>
	);
};

ReactDOM.render(<App />, document.getElementById("root"));

ialApplyPolyfills().then(() => {
	ialDefineCustomElements(window);
});
