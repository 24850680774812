const getCurrentDate = lang => {
	return new Date().toLocaleDateString(lang, {
		weekday: "short",
		year: "numeric",
		month: "numeric",
		day: "2-digit"
	});
};

export default getCurrentDate;
