import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	header: {
		alignItems: "center",
		background: theme.palette.background.light,
		display: "grid",
		gap: theme.spacing(2),
		gridTemplateColumns: "1fr auto 1fr",
		height: 160,
		left: 0,
		padding: theme.spacing(3),
		position: "fixed",
		top: 0,
		width: "100%",
		zIndex: 1
	},
	language: {
		alignItems: "center",
		display: "flex",
		justifyContent: "flex-start",
		position: "relative",
		"&::before": {
			background: theme.palette.divider,
			content: "''",
			height: 1,
			left: 0,
			position: "absolute",
			right: 0,
			top: "50%",
			transform: "translate(0, -50%)",
			zIndex: -1
		}
	},
	weather: {
		alignItems: "center",
		display: "flex",
		justifyContent: "flex-end",
		position: "relative",
		"&::before": {
			background: theme.palette.divider,
			content: "''",
			height: 1,
			left: 0,
			position: "absolute",
			right: 0,
			top: "50%",
			transform: "translate(0, -50%)",
			zIndex: -1
		}
	}
}));

export default styles;
