function aroundMeFilterIcons() {
	return [
		{
			identifier: "Apotheke",
			url: "/img/elements/around-me/filter/apotheken.svg"
		},
		{
			identifier: "pharmacy",
			url: "/img/elements/around-me/filter/apotheken.svg"
		},
		{
			identifier: "Restaurant",
			url: "/img/elements/around-me/filter/restaurant.svg"
		},
		{
			identifier: "Alm / Hütte / Berggasthof",
			url: "/img/elements/around-me/filter/alm.svg"
		},
		{
			identifier: "Pasture / hut / mountain lodge",
			url: "/img/elements/around-me/filter/alm.svg"
		},
		{
			identifier: "Infobüro",
			url: "/img/elements/around-me/filter/tvb.svg"
		},
		{
			identifier: "Tourist Office",
			url: "/img/elements/around-me/filter/tvb.svg"
		},
		{
			identifier: "Bergbahn / Seilbahn",
			url: "/img/elements/around-me/filter/bergbahn.svg"
		},
		{
			identifier: "mountain railway / cable railway",
			url: "/img/elements/around-me/filter/bergbahn.svg"
		},
		{
			identifier: "Bar / Pub / Disco",
			url: "/img/elements/around-me/filter/bar.svg"
		},
		{
			identifier: "Bar / Pub / Disco",
			url: "/img/elements/around-me/filter/bar.svg"
		},
		{
			identifier: "Geschäfte / Shops",
			url: "/img/elements/around-me/filter/shop.svg"
		},
		{
			identifier: "Shops",
			url: "/img/elements/around-me/filter/shop.svg"
		},
		{
			identifier: "Hallenbad",
			url: "/img/elements/around-me/filter/schwimmbaeder.svg"
		},
		{
			identifier: "indoor swimming pool",
			url: "/img/elements/around-me/filter/schwimmbaeder.svg"
		},
		{
			identifier: "Skibus / Wanderbus",
			url: "/img/elements/around-me/filter/bus.svg"
		},
		{
			identifier: "Skibus / Wanderbus",
			url: "/img/elements/around-me/filter/bus.svg"
		},
		{
			identifier: "Bus",
			url: "/img/elements/around-me/filter/bus.svg"
		},
		{
			identifier: "bus",
			url: "/img/elements/around-me/filter/bus.svg"
		},
		{
			identifier: "Modegeschäfte",
			url: "/img/elements/around-me/filter/mode.svg"
		},
		{
			identifier: "Fashion stores",
			url: "/img/elements/around-me/filter/mode.svg"
		},
		{
			identifier: "Erlebnispark / Freizeitpark",
			url: "/img/elements/around-me/filter/mountain.svg"
		},
		{
			identifier: "fun park / leisure park",
			url: "/img/elements/around-me/filter/mountain.svg"
		}
	];
}

export default aroundMeFilterIcons;
