import isEmpty from "lodash/isEmpty";

const getCurrentWeatherData = (weatherData = {}) => {
	if (isEmpty(weatherData)) return {};

	const { days = [] } = weatherData;

	const currentDate = new Date();
	currentDate.setHours(0, 0, 0, 0);

	const currentDayData = days?.find(
		item => new Date(item?.date).getTime() === currentDate.getTime()
	);

	return currentDayData || {};
};

export default getCurrentWeatherData;
