import { useEffect, useState } from "react";

const useWeatherData = identifier => {
	const [data, setData] = useState({});

	useEffect(() => {
		const fetchWeather = async () => {
			const { REACT_APP_PATH } = process.env;

			const api =
				REACT_APP_PATH +
				"/SkigebieteManager/Micado.SkigebieteManager.Plugin.WeatherApi/GetDetails.api?lang=de&client=https://sgm.skiwelt.at&region=skiwelt&day=3";

			try {
				const response = await fetch(api);
				const responseData = await response.json();

				const station = responseData?.find(item => item?.identifier === identifier);

				if (!station && responseData?.[0]) {
					setData(responseData?.[0]);
					return;
				}

				setData(station);
			} catch (error) {}
		};

		fetchWeather();
	}, [identifier]);

	return data;
};

export default useWeatherData;
