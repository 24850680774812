import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	item: {
		alignItems: "center",
		background: theme.palette.background.default,
		containerType: "inline-size",
		display: "flex",
		flexDirection: "column",
		gap: theme.spacing(1.5),
		justifyContent: "center",
		padding: theme.spacing(3),
		"& > div": {
			height: "auto !important",
			minWidth: 32,
			width: "clamp(32px, 20%, 64px) !important",
			"& svg": {
				height: "auto !important",
				width: "100% !important"
			}
		},
		[theme.breakpoints.up("md")]: {
			gap: theme.spacing(2)
		},
		[theme.breakpoints.up("lg")]: {
			gap: theme.spacing(3),
			padding: theme.spacing(4)
		},
		[theme.breakpoints.up("xl")]: {
			gap: theme.spacing(4),
			padding: theme.spacing(5)
		}
	},
	title: {
		fontSize: "clamp(20px, 3vw, 56px)",
		lineHeight: 1,
		textAlign: "center",
		[theme.breakpoints.up("lg")]: {
			marginTop: theme.spacing(1)
		},
		[theme.breakpoints.up("xl")]: {
			marginTop: theme.spacing(2)
		}
	}
}));

export default styles;
