import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import loadable from "@loadable/component";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import localDE from "./locals/de.json";
import localEN from "./locals/en.json";

import styles from "./ReactTourismServices.styles";

const Alert = loadable(() => import("@material-ui/lab/Alert"));
const Preloader = loadable(() => import("./components/Preloader"));
const List = loadable(() => import("./components/List"));

const ReactTourismServices = ({
	buttonLabel,
	categories = [],
	lang,
	pageName,
	providers = [],
	providerType = 1,
	selected = [],
	showBookmark = true,
	showDescription = true,
	showDetailsInOverlay = false
}) => {
	const { REACT_APP_PATH } = process.env;
	const [services, setServices] = useState({ items: [], _loading: true });
	const css = styles();
	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN
		}
	});

	useMemo(() => {
		const fetchData = async () => {
			try {
				const queryParams = new URLSearchParams({
					categories: categories.join(","),
					selected: selected.join(","),
					lang,
					providers: providers.join(","),
					providerType
				});

				const response = await fetch(
					`${REACT_APP_PATH}/Micado.Tourism.Web/Micado.Tourism.Web.IO.Api.ServiceApi/List.api?${queryParams}`,
					{ method: "GET" }
				);

				if (!response.ok) {
					throw new Error(response.statusText);
				}

				const data = await response.json();
				setServices({ items: data.items || [], _loading: false });
			} catch (error) {
				console.error(error);
				setServices({ items: [], _loading: false });
			}
		};

		fetchData();
		// eslint-disable-next-line
	}, [lang, providerType, REACT_APP_PATH]);

	const hasItems = !!services.items.length;

	return (
		<div className={clsx(css.reactTourismServices, "mco-tourism-services")}>
			{services._loading ? (
				<Preloader />
			) : (
				<>
					{hasItems ? (
						<List
							buttonLabel={buttonLabel}
							items={services.items}
							lang={lang}
							pageName={pageName}
							showBookmark={showBookmark}
							showDescription={showDescription}
							showDetailsInOverlay={showDetailsInOverlay}
						/>
					) : (
						<Alert
							className={clsx(css.noResult, "mco-tourism-services__no-result")}
							severity="error"
						>
							{l("reactTourismServices.errors.noEntries")}
						</Alert>
					)}
				</>
			)}
		</div>
	);
};

ReactTourismServices.propTypes = {
	buttonLabel: PropTypes.string,
	categories: PropTypes.array,
	lang: PropTypes.string,
	pageName: PropTypes.string,
	providerType: PropTypes.number,
	showBookmark: PropTypes.bool,
	showDescription: PropTypes.bool,
	showDetailsInOverlay: PropTypes.bool
};

export default ReactTourismServices;
