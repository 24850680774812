import React, { useState, useEffect } from "react";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import moment from "moment";
import SVGIcon from "../../helper/svgicon";

const useStyles = makeStyles(theme => ({
	day: {
		background: theme.palette.background?.light,
		padding: theme.spacing(2)
	},
	top: {
		display: "flex",
		flexDirection: "column",
		gap: theme.spacing(1)
	},
	dateLabel: {
		color: theme.palette.primary.main,
		fontSize: "1.4rem",
		fontWeight: theme.typography.fontWeightBold
	},
	stateText: {},
	icon: {
		display: "flex",
		justifyContent: "center",
		margin: theme.spacing(3, 0),
		"& svg path": {
			fill: theme.palette.primary.main + " !important"
		}
	},
	temps: {
		alignItems: "center",
		display: "flex",
		justifyContent: "center"
	},
	spacer: {
		background: theme.palette.grey[400],
		height: theme.spacing(4),
		margin: theme.spacing(0, 2),
		width: 1
	},
	tempMin: {
		textAlign: "right"
	},
	tempValue: {
		color: theme.palette.primary.main,
		fontSize: "1.6rem",
		fontWeight: theme.typography.fontWeightBold
	}
}));

const Weather = () => {
	const [weatherData, setWeatherData] = useState();

	useEffect(() => {
		async function fetchData() {
			const { REACT_APP_PATH } = process.env;

			const fetchURL =
				REACT_APP_PATH +
				"/SkigebieteManager/Micado.SkigebieteManager.Plugin.WeatherApi/GetDetails.api?lang=de&client=https://sgm.skiwelt.at&region=skiwelt&day=3";

			const response = await fetch(fetchURL);

			try {
				setWeatherData(await response.json());
			} catch (error) {}
		}

		fetchData();
	}, []);

	if (!weatherData) {
		return <></>;
	}

	return (
		<Box my={6}>
			<WeatherStation stationData={weatherData?.[0]} />
		</Box>
	);
};

const WeatherStation = ({ stationData }) => {
	if (!stationData) {
		return <></>;
	}

	const { days } = stationData;

	return (
		<Grid container spacing={2}>
			{days?.map(day => {
				return <Day key={day.date} dayData={day} />;
			})}
		</Grid>
	);
};

const Day = ({ dayData }) => {
	const { date, state = "", temperatureMin, temperatureMax } = dayData;
	const { t } = useTranslation();
	const css = useStyles();

	if (
		!date ||
		typeof temperatureMin === "undefined" ||
		typeof temperatureMax === "undefined"
	) {
		return <></>;
	}

	return (
		<Grid item xs={12} md={4}>
			<div className={css.day}>
				<div className={css.top}>
					<div>
						<Typography className={css.dateLabel}>
							{moment(new Date(date)).format("dddd")}
						</Typography>
						<Typography variant="caption" component="p">
							{moment(new Date(date)).format("DD.MM.YYYY")}
						</Typography>
					</div>
					<Typography className={css.stateText} variant="body1" component="p">
						{t(`site.weather.stateText.${state.toUpperCase()}`)}
					</Typography>
				</div>
				<div>
					<SVGIcon className={css.icon} src={`/img/icons/weather/${state}.svg`} size={128} />
					<div className={css.temps}>
						<div className={css.tempMin}>
							<div className={css.tempValue}>{temperatureMin} °C</div>
							<Typography variant="caption" component="p">
								{t("site.weather.min")}
							</Typography>
						</div>
						<div className={css.spacer} />
						<div>
							<div className={css.tempValue}>{temperatureMax} °C</div>
							<Typography variant="caption" component="p">
								{t("site.weather.max")}
							</Typography>
						</div>
					</div>
				</div>
			</div>
		</Grid>
	);
};

export default Weather;
