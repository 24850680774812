import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import { PageContext } from "@Core";
import useCurrentTime from "./hooks/useCurrentTime";
import useWeatherData from "./hooks/useWeatherData";
import getCurrentDate from "./utils/getCurrentDate";
import getCurrentTime from "./utils/getCurrentTime";
import getCurrentWeatherData from "./utils/getCurrentWeatherData";

import styles from "./Weather.styles";

const Weather = () => {
	const [pageData] = useContext(PageContext);
	const { t } = useTranslation();
	const currentTime = useCurrentTime();
	const weatherData = useWeatherData("scheffau");
	const css = styles();

	const lang = pageData?.data?.lang;

	const { state } = getCurrentWeatherData(weatherData);

	const formattedDate = getCurrentDate(lang);
	const formattedTime = getCurrentTime(currentTime, lang);

	return (
		<div className={css.weather}>
			{state && (
				<div className={css.icon}>
					<img alt="" height={56} src={`/img/icons/weather/${state}.svg`} width={56} />
				</div>
			)}
			<div className={css.content}>
				{formattedDate && (
					<Typography className={css.date} component="p" variant="h3">
						{formattedDate}
					</Typography>
				)}
				{formattedTime && (
					<Typography className={css.time} component="p" variant="h3">
						{`${formattedTime}${lang === "de" ? ` ${t("site.ui.labels.time")}` : ``}`}
					</Typography>
				)}
			</div>
		</div>
	);
};

export default Weather;
